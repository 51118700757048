<template>
  <page-main
    title="Experience"
    nextText="Done with your experiences? Let's talk about other information."
    :buttonTabIndex="addWorkTab + 1 + ''"
    eventName="nav"
    @nav="nav('/FinalInfo')"
    @save="save()"
  >
    <template v-slot:aside>
      This is where we start to go deeper into your work experience by adding to and editing your prior job history. In this section, you will make records for each position you've held (or wish to include) by providing the following information:
      <ol>
        <li><b>Organizations/Companies: </b>Information about the organization or company where you worked.</li>
        <li><b>Role: </b>Details about the position you held, including your job title and how long you held the position.</li>
        <li><b>Responsibilities: </b>A brief description of the duties you perform(ed) in this role.</li>
        <li><b>Accomplishments: </b>Any specific accomplishments you had while in the role. Be as specific as possible; highlighting your professional impact will help your resume shine!</li>
      </ol>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?" :maxWidth="800">
        <h3 class="font-weight-bold">Example #1:</h3>
        <v-system-bar height="30" color="primary" class="white--text elevation-5 my-4">
          Big Tech Company A
        </v-system-bar>
        <div class="px-4">
          <p>
            Vice President - Strategy and Operations<br />
            June 2023 - Present<br />
            Seattle, WA, USA
          </p>

          <p>
            Responsibilities:<br />
            Currently the VP for Strategy and Operations. Responsibilities include strategy development, business requirements, and implementation plans of Product A.
          </p>

          <p>
            Achievements:
            <ul>
              <li>
                Led several org-wide strategic initiatives and processes, including the development of 3-year vision and roadmaps, cost optimization strategy, annual strategic planning, quarterly business reviews, and 3-year organizational planning.
              </li>
            </ul>
          </p>
        </div>
        <h3 class="font-weight-bold mt-8">Example #2:</h3>
        <v-system-bar height="30" color="primary" class="white--text elevation-5 my-4">
          Big Tech Company B
        </v-system-bar>
        <div class="px-4">
          <p>
            Director - Strategy and Operations<br />
            June 2023 - Present<br />
            Chicago, IL, USA
          </p>
          <p>
            Responsibilities:<br />
            Currently the Director for Strategy and Operations. Responsibilities include strategy development, business requirements, and implementation plans of Product A.
          </p>
          <p>
            Achievements:
            <ul>
              <li>
                Created a strategic plan to migrate operations program workflows onto a unified system, leading to a potential 50% reduction in operating expenses.
              </li>
            </ul>
          </p>
        </div>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container fluid class="container-main">
      <v-row>
        <v-col
          cols="12"
          class="r-col"
          v-for="(item, i) in fullList"
          :key="item.key"
        >
          <list-card
            :title="item.general"
            @orderup="orderUp(item.key)"
            @orderdown="orderDown(item.key)"
            :disableUp="i === 0"
            :disableDown="i === fullList.length - 1"
          >
            <div class="d-flex justify-space-between align-center">
              <div>
                <div v-if="HasValue(item, 'roles')">
                  <div v-for="(role, i) in item.roles" :key="i">
                    {{ role }}
                  </div>
                </div>
                <div v-if="HasValue(item, 'date')">
                  {{ item.date }}
                </div>
                <div v-if="HasValue(item, 'location')">
                  {{ item.location }}
                </div>
                <div
                  v-if="
                    HasValue(item, 'responsibilities') ||
                      HasValue(item, 'directReports')
                  "
                  class="pt-2"
                >
                  Responsibilities:
                  <div
                    v-if="HasValue(item, 'responsibilities')"
                    class="text-body-2 pl-2"
                  >
                    {{ item.responsibilities }}
                  </div>
                  <div
                    v-if="HasValue(item, 'directReports')"
                    class="text-body-2 pl-2"
                  >
                    {{ item.directReports }}
                  </div>
                </div>
                <div v-if="HasValue(item, 'achievements')" class="pt-2">
                  Achievements:
                  <ul class="pl-6">
                    <li
                      v-for="ach in item.achievements"
                      :key="ach"
                      class="text-body-2"
                    >
                      {{ ach }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-column align-start">
                <v-btn
                  tile
                  text
                  @click="navWithKey('/WorkCompanyGeneral/', item.key)"
                  :tabindex="(i + 1) * 2"
                >
                  <v-icon class="pr-2"> mdi-pencil-outline </v-icon>
                  Edit
                </v-btn>
                <v-btn
                  tile
                  text
                  @click.stop="launchDialog(item)"
                  :tabindex="(i + 1) * 2 + 1"
                >
                  <v-icon class="pr-2">mdi-delete</v-icon>
                  Remove
                </v-btn>
              </div>
            </div>
          </list-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-4">
        <v-col cols="auto">
          <v-btn outlined @click="navNewRecord()" :tabindex="addWorkTab"
            >Add Work Record</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="dialog" title="You are about to remove:">
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary">{{ dialogDisplay }}</p>
        <span class="text-body-2">Are you sure?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="error" text @click="removeDialogItem()"> Remove </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import * as uuid from "../utility/guid";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListCard from "../components/ListCard.vue";
import { HasValue } from "../utility/general";

// @ is an alias to /src
export default {
  name: "Work",
  data: () => ({
    dialog: false,
    dialogKey: "",
    dialogDisplay: "",
  }),
  components: {
    "list-card": ListCard,
  },
  mounted() {
    if (this.fullList?.length > 0) {
      this.key = this.fullList[0].key;
    }
  },
  methods: {
    save() {
      this.saveWork();
    },
    ...mapMutations("work", [
      "createEntry",
      "removeEntry",
      "orderUp",
      "orderDown",
    ]),
    ...mapActions("work", ["saveWork"]),
    HasValue,
    navWithKey(path, key) {
      this.key = key;
      this.nav(path + key);
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    navNewRecord() {
      this.key = uuid.gen();
      this.createEntry({ key: this.key });
      this.$router.push({ path: "/WorkCompanyGeneral/" + this.key });
    },
    launchDialog(item) {
      this.dialogDisplay = item.general;
      this.dialogKey = item.key;
      this.dialog = true;
    },
    removeDialogItem() {
      this.removeEntry({ key: this.dialogKey });
      this.dialog = false;
    },
    roleDisplay(roles) {
      return roles?.reduce((p, c) => `${p}, ${c}`);
    },
  },
  computed: {
    ...mapGetters("work", ["fullList"]),
    ...mapFields("work", { key: "key" }),
    addWorkTab() {
      return ((this.fullList?.length ?? 0) + 1) * 2 + 3;
    },
    tips() {
      return [
        "If you held multiple roles with the same company, list each position as a separate work record.",
        "Ideally you want to include 1-3 accomplishments per listed work record.",
        "You can choose which work accomplishments  show up on your resume as you work through this section.",
        "Have no paid work experience? No worries, you can use this section to put in other experiences like research, leadership, volunteerism, etc.",
      ];
    },
  },
};
</script>
